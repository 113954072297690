<template>
  <div class="Demo">
    <CommonTable :columns="columns" :dataSource="tableData" :total="total">
      <!-- <template v-slot:amountWithoutTax="{ text, record, index }"></template> -->
    </CommonTable>
  </div>
</template>

<script>
import http from '@/service/axios'
import api from '@/service/api'
import CommonTable from '@/component/commonTable'
export default {
  name: 'Demo',
  components: { CommonTable },
  data() {
    return {
      tableData: [],
      total: 0,
    }
  },
  mounted() {
    this.getList()
  },
  computed: {
    columns() {
      return [
        {
          title: this.$t('名称'),
          dataIndex: 'name',
        },
        {
          title: 'CODE',
          dataIndex: 'type',
        },
      ]
    },
  },
  methods: {
    getList() {
      http({
        url: api.getSettlementMethodList,
        success: (res) => {
          this.tableData = res.result.list
          this.total = res.result.total
        },
      })
    },
  },
}
</script>
